.confirm-detail {
    margin: 0 15px;
  }
  .checkout-detail {
    display: flex;
    margin: 15px;
    justify-content: space-between
  }
  
  .confirm-info {
    color: #444;
    /* font-size: 2.3rem; */
  }
  
  .confirm-label {
    color: #bbb;
    display:block;
  }
  
  .confirm-value {
    color: #444;
    display:block;
    font-weight: 500;
  }

  .amount-container{
    border: solid 1px #444;
    margin: 50px 0px;
  }