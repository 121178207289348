
ul.objectToList {
    margin: 0.5em 0;
    padding: 0.5em;
    border: 1px dashed rgba(255,255,255,0.5);
    width: 100%;
    background-color: rgba(255,255,255,0.1);
}
.objectToList>li {
    list-style: none;
    text-indent: 0;
    margin: 0;
    padding: 0;
    font-size: 1.2rem;
}
.objectToList>li>b{
    display: inline-block;
    font-family: 'Gilroy Bold';
}
.objectToList>li:first-of-type {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    flex-direction: row;
    align-content: stretch;
    font-family: 'Gilroy Bold';
    border-bottom: 1px solid black;
}
.objectToList>li:first-of-type>*:first-child{
    letter-spacing: 0.2em;
    background: black;
    color: white;
    padding: 0 1em;
}
