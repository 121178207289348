.form-field.kyc-status {
    flex: auto;
    text-align: right;
    vertical-align: bottom;
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    align-content: flex-end;
    justify-content: flex-end;
}
.form-field.kyc-status>.button{
    margin:0;
    width: 100%;
}
.autoclick{
    position: absolute;
    top:-100px;
    left:-1000px;
    width:10px !important;
    height: 10px !important;
}
.kyc-status .button{
    margin: 0 1em;
    font-family: 'Gilroy Bold';
    padding:1em;
    text-align: center;
}