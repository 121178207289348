.header h3 {
    color: var(--brand-main-color);
    font-size: 3.5rem;
    font-weight: 500;
    margin-bottom: 0.2em;
}

.header h4 {
  font-size: 2rem;
  font-weight: 500;
  margin: 1em 0px;
}
.text-center{
  text-align: center;
}

/* .doc-upload .header {
    border-bottom: none;
  } */
  .doc-upload .text {
    margin-top: 30px;
    margin-bottom: 30px;
  }
  .doc-upload .text p,
  .doc-upload .text ul {
    /* color: #999999; */
    font-size: 15px;
    font-weight: 500;
  }
  .doc-upload .text ul {
    list-style: none inside;
  }
  .doc-upload .text ul li:before {
    content: '- ';
    margin-right: 20px;
  }

  .upload-form {
    max-width: 768px;
    margin: 0 auto;
    padding-top: 30px;
    padding-bottom: 20px;
  }
  .upload-form .upload {
    display: block;
    width: 180px;
    margin: 0 auto;
    text-align: center;
    cursor: pointer;
    position: relative;

  }
  .upload-form .upload input {
    position: absolute;
    height: 100%;
    width: 100%;
    z-index: 9;
    opacity: 0;
    left: 0;
    cursor: pointer;  }

  .upload-form .upload p {
    margin-top: 10px;
  }
  .upload-form .upload-area {
    position: relative;
    width: 100%;
    padding-bottom: 100%;
    border-radius: 50%;
    border: 2px dashed #bfbfbf;
    cursor: pointer;
  }
  .upload-form .upload-area .icon--document {
    max-width: 40%;
    position: absolute;
    top: 50%;
    left: 50%;
    -ms-transform: translate(-50%, -50%);
        transform: translate(-50%, -50%);
    pointer-events: none;
  }
  .upload-form .upload-area .icon--plus {
    display: table;
    width: 42px;
    height: 42px;
    color: white;
    background-color: #7ed321;
    border-radius: 100%;
    box-shadow: 0 0 0px 5px white;
    position: absolute;
    padding-top: 5px;
    top: 14%;
    left: 14%;
    -ms-transform: translate(-50%, -50%);
        transform: translate(-50%, -50%);
  }
  .upload-form .upload-area .icon--plus:before {
    display: table-cell;
    vertical-align: middle;
    text-align: center;
  }
  .upload-form.dragover .upload-area {
    border-color: #7ed321;
  }
  .upload-form .preview-area {
    display: none;
    position: relative;
    margin: 40px 0;
    padding: 0 15px;
    background-color: #f0f0f0;
    text-align: center;
  }
  .upload-form .preview-area:before {
    content: '';
    display: inline-block;
    border-bottom: 20px solid #f0f0f0;
    border-left: 20px solid transparent;
    border-right: 20px solid transparent;
    position: absolute;
    top: -20px;
    left: 50%;
    -ms-transform: translateX(-50%);
        transform: translateX(-50%);
  }
  .upload-form .upload-error {
    /* display: none; */
    padding: 10px;
    margin: 10px;
    color: var(--brand-main-color);
    border: 2px solid var(--brand-main-color);
    border-radius: 4px;
    background-color: white;
    font-weight: bold;
  }
  .upload-form .file-list {
    list-style: none;
    display: table;
    width: 100%;
    margin: 0;
    padding: 10px 0;
  }
  .upload-form .file-list__item {
    display: table-row;
    /* color: #7f7f7f; */
    font-weight: bold;
  }
  @media (max-width: 450px) {
    .upload-form .file-list__item {
      display: flex;
     flex-wrap: wrap;
     justify-content: center;
    }
  }
    .upload-form .file-list__item__section {
    display: table-cell;
    vertical-align: middle;
    text-align: left;
    padding: 6px 2px;
  }
  .upload-form .file-list__item__section:first-of-type {
    width: 1em;
    font-size: 1.2em;
  }
  .upload-form .file-list__item__section:last-of-type {
    width: 40%;
  }
  .upload-form .file-list__item .file-uploaded {
    display: block;
    color: #7ed321;
  }
  .upload-form .file-list__item .delete-file {
    display: block;
    cursor: pointer;
  }
  .upload-form .fail {
    color: var(--brand-main-color);
  }
  .upload-form .file-list__item.error .upload-progress__bar {
    background-color: var(--brand-main-color);
  }
  .upload-form .file-list__item.complete .upload-progress__bar {
    background-color: #7ed321;
    width: 100%;
  }
  .upload-form .file-list__item.complete .file-uploaded {
    display: block;
  }
  .upload-form .file-list__item.complete .delete-file {
    display: none;
  }
  .upload-form .upload-btn {
    -ms-transform: translateY(50%);
        transform: translateY(50%);
    margin-top: -20px;
    outline: none;
    padding-left: 30px;
    padding-right: 30px;
  }
  .btn-lightgreen {
    color: #fff;
    background-color: #7ed321;
    border-color: #7ed321;
color: #fff;
width: 100%;
font-size: 14px;
font-weight: 600;
/* border-radius: 19px; */
height: 39px;
/* text-transform: capitalize; */
  }
  .upload-form .upload-progress {
    display: block;
    width: 100%;
    border: 1px solid #d1d1d1;
    border-radius: 9999px;
    background-color: white;
    overflow: hidden;
  }
  .upload-form .upload-progress__bar {
    height: 100%;
    max-width: 100%;
    display: inline-block;
    background-color: #4a90e2;
    border-radius: 9999px;
    border: 2px solid white;
  }

   .preview-area {
    /* display: none; */

    position: relative;

    margin: 40px 0;
    padding: 0 15px;
    background-color: #f0f0f0;
    text-align: center;

    /* &:before { */
        content: '';
        display: inline-block;
        border-bottom: 20px solid #f0f0f0;
        border-left: 20px solid transparent;
        border-right: 20px solid transparent;

        position: absolute;
        top: -20px;
        left: 50%;
        transform: translateX(-50%);
    /* } */
}


.file-list {
  list-style: none;
  display: table;
  width: 100%;

  margin: 0;
  padding: 10px 0;

  /* &__item {
      display: table-row;
      color: #7f7f7f;
      font-weight: bold;

      &__section {
          display: table-cell;

          vertical-align: middle;
          text-align: left;
          padding: 6px 2px;

          &:first-of-type {
              width: 1em;
              font-size: 1.2em;
          }
          &:last-of-type {
              width: 40%;
          }
      }

     

      &.error {
          color: @osper-red;
          .upload-progress__bar {
              background-color: @osper-red;
          }
      }

      &.complete {
          .upload-progress__bar {
              background-color: #7ed321;
              width: 100%;
          }
          .file-uploaded {
              display: block;
          }
          .delete-file {
              display: none;
          }
      }
  } */

}

.file-uploaded {
  display: block;
  color: #7ed321;
}
.delete-file {
  display: block;
  cursor: pointer;
}

.MuiLinearProgress-root{
  height: 1em !important;
  border: 1px solid #d1d1d1 !important;
  border-radius: 9999px !important;
}
