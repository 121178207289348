.scans {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: flex-start;
}
.documentSlot {
    display: block;
    min-height: 10em;
    padding: 1em;
    border: 1px solid lightgray;
    flex-grow: 1;
    margin: 0.25em;
    position: relative;
}
.documentSlot .buttons{
    margin:1em 0;
    display: flex;
    gap: 1em;
}
.documentSlot .buttons>*{
    flex: 1 1;
    padding: 0.5em;
    margin: 0;
}
.uploadedFile img, .uploadedFile embed{
    background-color: #00000010;
    margin: 0.5em 0.5em 1em 0.5em;
    min-width: 2em;
    min-height: 2em;
    max-width: 43%;
}
.uploadedFile progress {
    position: absolute;
    bottom: 0em;
    left: 0em;
    max-width: 43%;
    opacity: 0.8;
    transition: width 1s;
    margin: 0 0.5em;
}
.uploadedFile progress.hidden {
    width:0;
    left:-1000px;
}
.uploadedFile{
    position: relative;
    border-radius: 0.5em;
    display: flex;
    flex-direction: row;
    align-content: flex-start;
    justify-content: center;
    align-items: center;
    flex-wrap: nowrap;
}
.uploadedFile .resultMsg{
    font-family: "Gilroy Bold", sanserif;
}
.uploadedFile .actionMsg{
    line-height: 1em;
    font-size: 0.9em;
    padding: 0.5em;
}

.uploadedFile.processing {
  animation-name: backgroundColorPalette;
  animation-duration: 2s;
  animation-iteration-count: infinite;
  animation-direction: alternate;
}
.uploadedFile.processing .icon{
    animation: 3s linear 0s infinite running rotation ;
    background-color: transparent !important;
    width: 2em;
    height: 2em;
    line-height: 2.3em;
    font-size: 2em;
    color: white;
}
@keyframes backgroundColorPalette {
  0% {background: #099dff;}
  100% {background: white;}
}
@keyframes rotation {
  0% {transform: rotate(0deg);}
  100% {transform: rotate(360deg);}
}

.camera canvas,.camera video{
    max-width: 80%;
    max-height: 50%;
    flex: 0 0;
}
.camera {
    position: fixed;
    display: flex;
    top: 1vh;
    left: 1vw;
    width: 98vw;
    height: 98vh;
    background-color: #6c757d;
    box-shadow: 0 0 8px black;
    z-index: 9999;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.camera .hidden{
    position: absolute;
    top: -5000px;
}
.cameraBackdrop {
    position: absolute;
    top:50%;
    color: white;
    font-family: 'Gilroy Bold';
    z-index: -1;
}
.cameraButtons>* {
    flex: 1 1;
    margin: 0.5em;
    height: 100%;
}

.cameraButtons {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 90%;
    align-content: flex-start;
    justify-content: space-around;
    height: 4em;
    margin: 2em auto;
}
.documentSlot img.placeholder{
    width: calc(100% + 2em);
    opacity: 50%;
    position: relative;
    margin: 0 -1em -1em -1em;
}