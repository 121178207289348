.testHelper{
    display: flex;
    flex-direction: column;
    position: fixed;
    top:0em;
    right:1em;
    z-index: 9999;
    background-color: red;
    padding:1em 0.5em 0.5em;
}
.testHelper>*{
    display:block;
    width:100%;
    height:2em;
    margin:1px;
    color:white;
}
.testHelper>button{
    border: 1px solid gray;
    border-radius: 0.5em;
    color:red;
}