.onboarding td{
    padding:2px;
}
table.onboarding{
    width: 100%;
}
.onboarding tr{
    border-bottom: 1px dotted;
}
.onboarding tr:hover{
    background-color: white;
}
.onboarding td>a{
    text-decoration: none;
}
.onboarding td b{
    font-family: "Gilroy Bold", sanserif;
}
.onboarding td>button {
    margin: 0.2em 0;
    font-size: 0.8em;
    color:#aaaaaa;
    background-color: transparent;
    border: 1px solid #aaaaaa;
    box-shadow: 0 0 0 transparent;
}
.onboarding td>button:hover {
    color: var(--brand-main-color);
}

.onboarding th[data-sort] {
    cursor: pointer;
    background-color: white;
}
.onboarding th[data-sort]:hover {
    border-bottom: 1px solid black;
}