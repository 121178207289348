.kyc>* {
    width: auto;
    min-width: 20em;
    max-width: 36em;
    margin: 0;
    flex:1;
}
.personForm {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: row;
    flex-wrap: wrap;
    align-content: stretch;
    min-width: 20em;
    padding-bottom: 2em;
}
.personForm.disabled {
    opacity: 0.5;
    pointer-events: none;
}
.personForm>.form-field {
    flex: auto;
    width: 46%;
}
.personForm>.form-field:last-child {
    display: flex;
    justify-content: flex-end;
}
.personForm>.form-field:last-child button{
    margin:1em 0;
    width: 48%;
    height: 3em;
}
.form-section.kyc {
    max-width: 90vw;
    align-items: flex-start;
}
.documentSlots {
    margin: 1em 0.2em;
}
footer ul{
    font-size: 0.6em;
}
input.status {
    pointer-events: none;
}
.kycMessage{
    transition-property: background-color, color;
    transition-duration: 2s;
    background-color: var(--brand-reverse-color);
    position: relative;
    height:auto;
    color: var(--brand-reverse-color);
    padding: 1em 1em;
    text-align: center;
    margin-top:2px;
    overflow: clip;
}
.kycMessage.maximized {
    background-color: var(--brand-main-color);
    color: var(--brand-reverse-color);
}
.kycMessage.minimized {
    background-color: rgba(255,255,255,0);
    color: #099dff;
}
.modal {
    position: fixed;
    top:0;
    left: 0;
    width:100%;
    height: 100%;
    background-color: var(--brand-reverse-color);
    color:white;
    z-index: -10;
    transition-property: z-index, background-color;
    transition-duration: 1s;
    display: flex;
    align-content: center;
    align-items: center;
    justify-content: center;
}
.modal.loading {
    z-index: 9999;
    background-color: #099dffdd;
}
.modal.processing {
    z-index: 9999;
    background-color: #099dffdd;
}