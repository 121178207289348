header.onboarding {
    display: flex;

    align-content: space-between;
    justify-content: flex-start;
    align-items: baseline;
    flex-direction: row;
    flex-wrap: nowrap;
    color: var(--brand-main-color);
    margin-bottom: 2em;
}
header.onboarding>* {

    font-family: 'Gilroy Bold';
}
header.onboarding>.spacer{
    flex:1;
}
header.onboarding svg{
    transform: translateY(10px);
    padding: 7px 0;
    margin: -1em 2em 0em 0;
    height: 4em;
}
header.smallprint{
    margin: 1em 0;
}

.kyb-form {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: stretch;
    justify-content: flex-start;
    align-content: flex-start;
}
.form-section {
    background-color: #eeeeee;
    color: #545454;
    margin-bottom: 1em;
    padding-bottom: 1em;
    max-width: 29em;
    margin-left: 3px;
    flex: 1;
    min-width: 19em;
}
.form-section>header{
    display: flex;
    flex-wrap: wrap;
}
.form-section>header>label {
    padding:0.4em;
    background-color: #099dff;
    color:white;
    flex:1;
    height: 2em;
}
.form-section>header>b{
    width: 2em;
    height: 2em;
    display: inline-block;
    padding: 0.4em;
    text-align: center;
    background-color: #099dff;
    color:white;
    font-family: "Gilroy Bold", sanserif;
    margin-right:1px;
}
.form-section>header>.subtitle{
    flex: 0 0 100%;
    color: #099dff;
    font-family: "Gilroy Bold", sanserif;
    padding:0.4em;
}
.form-field {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-content: flex-start;
    justify-content: flex-start;
    align-items: flex-start;
    margin: 0 0.5em;
    width: -webkit-fill-available;
}
.form-field.space-before {
    margin-top: 2em;
    flex: 100% !important;
}
.form-field.half-width {
    width: 45%;
    margin-right: 0;
}
.form-field label{
    width: 100%;
    font-size: 0.8em;
    margin-bottom: 0.2em;
    padding-left: 0.3em;
    margin-top: 0.5em;
}
.form-field input, .form-field textarea, .form-field select{
    border: 0;
    background-color: #fafafa;
    border-radius: 4px;
    width: 100%;
    margin-top: -0.2em;
    padding: 0.4em 0.5em;
    font-weight: bold;
    transition: background-color;
    transition-duration: 1s;
    /*box-shadow: inset 0 0 8px rgb(0 0 0 / 20%);*/
}
.submitted .form-field input,
.submitted .form-field textarea,
.submitted .form-field select{
    box-shadow: none;
    background: transparent;
    color: black;
    padding: 0 0.25em;
    pointer-events: none;
}
input.percentage {
    width: 4em;
    text-align: right;
    direction: rtl;
}
input.date {
    color:black;
    font-family: inherit;
    font-size: inherit;
    appearance: none;
    margin:0;

}
.form-field.percentage::after {
    content: "%";
    opacity: 0.5;
    font-size: 2em;
    margin: -0.2em 0.1em;
}
.form-field .updatedByOthers{
    background-color: lawngreen;
    transition: background-color;
    transition-duration: 1s;
}
.checklist {
    padding:0 0 0 0;
    width: 100%;
}
.checklist.yesno {
    display:flex;
    flex-direction: row;
}
.checklist li,.checklist-with-others li{
    padding-left:1em;
    width: 50%;
}
/*.checklist-with-others .checklist>*>*:first-child::before {*/
/*    content: ">";*/
/*    margin: 0 -1em;*/
/*    display: block;*/
/*    float: left;*/
/*    top: 0px;*/
/*    color: var(--brand-main-color)*/
/*}*/
.checklist>* {
    display: flex;
    flex-direction: row;
    padding: 0.35em 0px 0.15em 0;
    flex-wrap: wrap;
}
.checklist>*:hover{
    background-color: #dddddd;
}
.checklist input[type=checkbox], .checklist input[type=radio]{
    width: 1em;
    margin: 0.2em 0.2em 0em 0em
}
.checklist label{
    width:auto;
    flex:1;
    padding:0 1.5em 0 0.2em;
    margin:auto;
}
.warning {
    color: red;
    font-style: italic;
    border:1px solid;
    padding: 0 1em !important;
}
.warning.off {
    display:none;
}
.matrix tr>*{
    font-size: 0.8em;
    height: 2.5em;
}
.matrix th{
    white-space: nowrap;
}
.fieldset {
    position: relative;
    margin-bottom: 1em;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-content: flex-start;
    align-items: stretch;
    justify-content: flex-start;
}
.listOfFieldsets header{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    border-top: 2px solid #cccccc;
    box-shadow: 0px -4px 4px rgb(0 0 0 / 5%);
    width:100%;
    padding-bottom: 1em;
}
.listOfFieldsets .icon{
    content: attr(data-index);
    width: 2em;
    height: 2em;
    line-height: 2em;
    position: relative;
    display:block;
    text-align: center;
    background-color: #cccccc;
    font-size: 0.6em;
    color: black;
    font-family: 'Gilroy Bold';
}
.icon.button {
    cursor: pointer;
}
.icon.button:hover{
    background-color: var(--brand-main-color);
    color: var(--brand-reverse-color)
}
.icon.button.removeItem:hover{
    background-color: red;
}
.list.button {
    text-align: right;
    width: 100%;
    padding-right: 1em;
    font-size: 0.8em;
    color: var(--brand-main-color);
    cursor: pointer;
}
.list.button:hover {
    color:var(--brand-reverse-color)
}
button.auxiliary{
    background-color: var(--main-background-color);
    color: var(--main-text-color);
    margin: 0;
}
button, .button {
    box-shadow: 0 0 8px rgb(0 0 0 / 20%);
}
input, button, .button {
    transition: background-color 1s;
}
input[disabled], button.disabled, input.disabled, .disabled>input {
    background-color: transparent;
    pointer-events: none;
}
.savingStatus {
    position: fixed;
    top: -1.3em;
    left: 0vw;
    width: 50vw;
    z-index: 9999;
    height: 1.5em;
    text-align: center;
    background: var(--main-text-color);
    color: var(--main-background-color);
    transition-property: top, background-color;
    transition-duration: 1s;
}

.savingStatus.visible,.savingStatus:hover {
    top:0;
}

.savingStatus.saved, .savingStatus.loaded {
    background-color: forestgreen;
}
.savingStatus.dirty {
    background-color: orange;
}
.savingStatus.load-error, .savingStatus.save-error {
    background-color: red;
}
.savingStatus.saving, .savingStatus.loading {
    background-color: lawngreen;
}
.kyc {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: flex-end;
    align-content: center;
    border: 1px solid white;
    border-radius: 0.5em;
    padding: 0 0.5em;
}

label>p{
    margin:0;
    padding:0;
}

.kyc .form-field.select {
    width: 100%;
}
.validationSummary {
    max-height: 7em;
    overflow: scroll;

}
.validationSummary>span:nth-child(1n+2){
    display: list-item;
    color: red;
    font-size: 0.8em;
}

.form-field.kyc-status.status-EKYC .button {
    background-color: yellow;
}
.form-field.kyc-status.status-SCAN .button {
    background-color: lightgoldenrodyellow;
}
.form-field.kyc-status.status-PASS .button,
.form-field.kyc-status.status-FAIL .button
{
    background-color: white;
}
footer.form-section {
    flex: 0 0 100%;
    max-width: 100%;
    padding: 0.5em;
    display: flex;
    justify-content: flex-end;
}
footer.form-section button{
    padding: 0 1em;
    height:3em;
    margin:0.5em 1em;
}





