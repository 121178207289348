.problems {
    position: fixed;
    top: -1px;
    left: 51vw;
    width: 45vw;
    z-index: 9998;
    /* color: white; */
    background-color: floralwhite;
    padding: 1em;
    border: 1px solid red;
    border-radius: 0 0 1em 1em;
    max-height: 6em;
    box-shadow: 4px 4px 8px rgb(200 22 0 / 50%);
    overflow: scroll;
}
.problems ol{
    margin:0;
}

.problems .error{
    cursor: pointer;
}

.pointer {
    position: absolute;
    top: 0;
    left: 0;
    width: 20px;
    height: 20px;
    display: block;
    z-index: 99999;
    border: 2px red solid;
    border-width: 6px 0 0 6px;
    transition: left 1s, top 1s, right 1s;
    transform: rotate(135deg);
    animation: 0.5s linear 0s infinite alternate pointerShake;
}

@keyframes pointerShake {
    0% {transform: translateX(0px) rotate(135deg);}
    100% {transform: translateX(1em) rotate(135deg);}
}