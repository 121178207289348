:root {
    --header-height: 5rem;
    --footer-height: 2rem;
    --brand-main-color: #1830ad;
    --brand-reverse-color: white;
    --main-background-color: #f6f5f0;
    --main-text-color: #555555;
    --reverse-background-color: #666666;
    --reverse-text-color: #bbbbbb;
    --success-background-color: #66B114;
    font-size: 62.5%;
}

body {
    background-color: var(--main-background-color);
    font-size: 1.6rem;
}

.wrapper {
    max-width: 123rem !important;
    width: 100%;
    margin: auto !important;
    padding-right: 3rem;
    padding-left: 3rem;
}

#root>.wrapper{
    margin-top: 0em !important;
    margin-bottom: 2em !important;
}

.parent{
    padding-top: 7.2rem;
padding-bottom: 9.6rem;
position: relative;
}

.headerWrapper {
    max-width: 138rem !important;
    width: 100%;
    margin: auto !important;
    padding: 1rem;
}

.sectionHeader {
    color: #1830ad;
    margin-top: 3rem;
    margin-bottom: 1rem;
    position: relative
}

.sectionHeader .h4:before,
.sectionHeader h4:before {
    content: "";
    width: 3.5rem;
    height: .7rem;
    background-color: #1830ad;
    position: absolute;
    left: 0;
    top: -2.8rem
}

.heading{
    font-size: 4.2rem;
}

.heading_error{
    color: #D0021B !important;
}

.sectionHeader .h4+p,
.sectionHeader h4+p {
    font-size: calc(2.04rem + .25vw);
    line-height: 1.25;
    letter-spacing: normal
}

@media (min-width:90em) {
    .sectionHeader h4+p {
        font-size: 2.4rem
    }
}

body {
    color: #545454;
    font-family: Lota Grotesque Light, sans-serif;
    font-size: 1.6rem;
}

#root .MuiInputBase-root,
#root .MuiFilledInput-root {
    border-radius: 0;
}

#root .MuiInputBase-input {
    font-family: Lota Grotesque Light, sans-serif;
    background-color: #e8e7e3;
    border: 0.2rem solid #e8e7e3;
}

.MuiFilledInput-root {
    background-color: #e8e7e3;
    border: 0.2rem solid #e8e7e3;
    transition: border-color .3s ease-in-out;
    line-height: 1.33;
    opacity: 1;
    color: #545454;
}

.MuiFilledInput-underline:before {
    border-bottom: none !important;
}

.MuiFilledInput-underline:after {
    border-bottom: #1830ad;
    border-bottom: 0.2rem solid var(--brand-main-color);
}

.MuiFilledInput-underline:after {
    content: none !important;
}

#root .MuiFormHelperText-root {
    font-size: 0.8em;
    font-family: inherit;
}

.MuiFilledInput-root.Mui-focused {
    border: 0.2rem solid #888680 !important;
}

.MuiFilledInput-root.Mui-error {
    border: 0.1rem solid #D0021B !important;
}

.MuiFilledInput-underline:after {
    content: none !important;
}

header .MuiSvgIcon-root {
    width: 2em;
    height: 2em;
}

.MuiSvgIcon-root {
    width: 1.5em !important;
    height: 1.5em !important;
}

.checkbox .MuiFormControlLabel-root {
    display: flex;
    width: 100%;
    align-items: flex-start !important;
    display: block;
    margin: 0 0 .6rem;
    text-align: left;
    font-family: Gilroy Bold, sans-serif;
    line-height: 1.5;
}

.MuiBackdrop-root {
    z-index: 9999 !important;
}

#dialog .MuiBackdrop-root {
    z-index: -1 !important;
}

.checkbox .MuiCheckbox-root {
    /* width: 10%; */
    align-self: flex-start;
    padding: 0 15px 5px 7px;
    margin: 2px -8px 0 -8px;
}

.checkbox .MuiTypography-body1 {
    width: 90%;
}

@media (max-width: 585px) {
    .checkbox .MuiCheckbox-root {
        width: 10%;
        align-self: flex-start;
        padding: 0;
        margin: 2px -1px 0 -1px;
    }
}

.error {
    color: #f44336;
    font-size: 0.8em;
    font-weight: 400;
    letter-spacing: .39996px;
    line-height: 19.9167px;
    margin: -3px 14px 0 11px;
    text-align: left;
}

.postcode-component {
    display: flex;
}

#root .postcode-component .auxButton {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    height: 2.876em;
    margin: 4px 0 0 0;
}


/* @media (max-width: 600px) {
    .postcode-component {
align-items: center;      }
    .postcode-component .MuiButtonBase-root{
      margin: 0px 0 0px 0;
    }
  } */

.padDiv {
    background-color: #f6f5f0;
    padding: 2rem
}

.parent:before {
    content: "";
    position: absolute;
    z-index: 0;
    background-image: url(assets/images/icon-dots_8b1d8376.svg);
    background-size: cover;
    background-repeat: no-repeat;
    background-position: 0;
    left: 1rem;
    top: 25rem;
    -webkit-transform: translateY(-25%);
    transform: translateY(-25%);
    display: none
}

@media (min-width:62em) {
    .parent:before {
        display: block;
        width: 7.02rem;
        height: 13.14rem
    }
}

@media (min-width:75em) {
    .parent:before {
        width: 7.41rem;
        height: 13.87rem
    }
}

@media (min-width:90em) {
    .parent:before {
        width: 7.8rem;
        height: 14.6rem
    }
}

.parent:after {
    content: "";
    position: absolute;
    z-index: 0;
    background-image: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCAxODMuNDcgNDY1LjAzIj48ZGVmcz48c3R5bGU+LmNscy0xe2ZpbGw6IzA5OWRmZjt9PC9zdHlsZT48L2RlZnM+PGcgaWQ9IkxheWVyXzIiIGRhdGEtbmFtZT0iTGF5ZXIgMiI+PGcgaWQ9IkxheWVyXzEtMiIgZGF0YS1uYW1lPSJMYXllciAxIj48cGF0aCBjbGFzcz0iY2xzLTEiIGQ9Ik01Mi4yOCw4My4zMUEyNDAuMTQsMjQwLjE0LDAsMCwxLDE4My40NywwaDBWNjIuM2ExODAuMTMsMTgwLjEzLDAsMCwwLTg0LjMzLDU4LjQ5LDE3OSwxNzksMCwwLDAsMCwyMjMuNDQsMTgwLjIyLDE4MC4yMiwwLDAsMCw4NC4zMyw1OC41VjQ2NWgwQTIzOS4wNiwyMzkuMDYsMCwwLDEsNTIuMjgsODMuMzFaIi8+PC9nPjwvZz48L3N2Zz4K);
    background-size: cover;
    background-repeat: no-repeat;
    background-position: 0;
    bottom: 10%;
    -webkit-transform: translateY(10%);
    transform: translateY(10%);
    display: none
}

@media (min-width:62em) {
    .parent:after {
        display: block;
        right: 0;
        width: 7.29rem;
        height: 20.25rem
    }
}

@media (min-width:75em) {
    .parent:after {
        width: 7.695rem;
        height: 21.375rem
    }
}

@media (min-width:90em) {
    .parent:after {
        width: 8.1rem;
        height: 22.5rem
    }
}

a {
    color: rgb(69, 77, 92);
    text-decoration: underline;
}

a:hover,
a:focus {
    color: var(--brand-main-color);
    text-decoration: underline;
}

.MuiRadio-colorSecondary.Mui-checked {
    color: var(--brand-main-color) !important;
}

.MuiCheckbox-colorSecondary.Mui-checked {
    color: var(--brand-main-color) !important;
    color: #1830ad !important;
}

footer.footer {
    font-family: Lota Grotesque Light, sans-serif;
    background-color: var(--brand-main-color);
    background-color: #1830ad;
    padding: 5.5rem 0 4.9rem;
    color: var(--brand-reverse-color)
}

footer.footer .footer_address {
    margin-bottom: 1.5rem
}

footer a {
    /* font-family: Gilroy Bold, sans-serif; */
    margin-bottom: 1rem;
    color: var(--brand-reverse-color);
    font-size: 1.4rem;
    text-decoration: none;
    display: block;
    line-height: 1.71
}

footer a:hover,
footer a:focus {
    color: #e6e6e6;
    text-decoration: none;
}

@media (min-width:48em) {
    footer.footer .footer_address {
        margin-bottom: 2.5rem
    }
}

@media (min-width:62em) {
    footer.footer .footer_address {
        margin-bottom: 2.5rem
    }
}

footer.footer .footer_address p {
    font-size: 1.8rem;
    line-height: 1.71
}

.MuiTypography-body1 {
    font-family: Gilroy Bold, sans-serif !important;
    font-size: 1.5rem !important;
}

.errorMessage {
    color: #f44336;
    width: 100%;
  }

@font-face {
    font-family: Gilroy Bold;
    src: url(assets/fonts/Gilroy-Bold_468998b9.woff2) format("woff2"), url(assets/fonts/Gilroy-Bold_5996d775.woff) format("woff"), url(assets/fonts/Gilroy-Bold_342a4e77.ttf) format("truetype"), url(assets/images/Gilroy-Bold_552febb4.svg) format("svg");
    font-weight: 700;
    font-style: normal;
    font-display: swap
}

@font-face {
    font-family: Lota Grotesque Bold;
    src: url(assets/fonts/LotaGrotesque-Bold_a911cb61.woff2) format("woff2"), url(assets/fonts/LotaGrotesque-Bold_8e43f024.woff) format("woff"), url(assets/fonts/LotaGrotesque-Bold_59db97b5.ttf) format("truetype"), url(assets/images/LotaGrotesque-Bold_5d631563.svg) format("svg");
    font-weight: 700;
    font-style: normal;
    font-display: swap
}

@font-face {
    font-family: Lota Grotesque Regular;
    src: url(assets/fonts/LotaGrotesque-Regular_cd290b97.woff2) format("woff2"), url(assets/fonts/LotaGrotesque-Regular_81fa4995.woff) format("woff"), url(assets/fonts/LotaGrotesque-Regular_57f6a110.ttf) format("truetype"), url(assets/images/LotaGrotesque-Regular_af505634.svg) format("svg");
    font-weight: 400;
    font-style: normal;
    font-display: swap
}

@font-face {
    font-family: Lota Grotesque Light;
    src: url(assets/fonts/LotaGrotesque-Light_043a57be.woff2) format("woff2"), url(assets/fonts/LotaGrotesque-Light_ed6e2ffc.woff) format("woff"), url(assets/fonts/LotaGrotesque-Light_b58c0ebe.ttf) format("truetype"), url(assets/images/LotaGrotesque-Light_0212f721.svg) format("svg");
    font-weight: 300;
    font-style: normal;
    font-display: swap
}

.center{
    text-align: center;
}

.cursor{
    cursor: pointer;
}
.alignRight{
    text-align: right;
}
.avatar{
    border-radius: 3em;
    position: relative;
    right: 0;
    top: 1em;
    height:3em;
}