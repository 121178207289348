
small {
  width:100%;
  margin: 0 0;
  display: inline-block;
  opacity: 0.7;
}
@media only screen and (max-width: 900px) {
  small {
    margin: 0 1em !important;
    width: auto !important;
  }
}

 .formLabel {
  display: block;
  margin: 0 0 .6rem;
  text-align: left;
  font-family: Gilroy Bold, sans-serif;
  line-height: 1.5;

  /* color: #555555;
  margin:0;
  text-align: right;
  font-size: 1.125rem;
  font-weight: 700;
  font-family: "Museo Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
  line-height: 1em; */
}
 .formLabel.value {
  text-align: left;
  font-weight: bold;
  color: black;
  line-height: 1.5rem;
}
 .formLabel>small {
  position: relative;
  top: 0em;
  left: 0;
}

@media only screen and (max-width: 600px) {
   .formLabel {
    text-align: left;
    margin: 0.25em 5px 0px 0;
    position: relative;
  }

  .formLabel>small {
    position: unset;
  }

   .MuiTextField-root{
    margin: 0;
  }
}
.expandBtn {
  cursor: pointer;
  color: var(--brand-main-color);
  margin:0.5em 0 0 0;
}
.formSpacer {
  width: 100%;
  height:2em;
}

.labelCell{
    display: flex;
    /* align-items: flex-end; */
    flex-direction: column;
    justify-content: center;
    position: relative;
    margin-top: 5px !important;
margin-bottom: -10px !important;
}
@media (max-width: 600px){
 .labelCell{
  align-items: flex-start;
 }
}


