.osperOnboardingForm {
	margin:3em;
}
.osperOnboardingForm .formRow{
    display: flex;
	margin-bottom: 2px;
}
.osperOnboardingForm .formRow>*:first-child {
	flex-basis: 30vw;
	flex-grow: 0;
	flex-shrink: 0;
	margin: 0 1em 0em 1em;
	padding: 0 0em 0em 1em;
	border-bottom: 1px dotted gray;
}
.osperOnboardingForm .formRow>* {
	flex-basis: 30vw;
	flex-grow: 1;
	flex-shrink: 1;
}
.osperOnboardingForm .formRow>a{
	text-align: center;
    display: block;
    padding: 20% 0;
    border: 1px solid black;
}
.osperOnboardingForm .child{
	margin-left: 2em;
}
.osperOnboardingForm textarea{
	display: block;
    width: 50vw;
	font-size: 0.8em;
    margin: 1em;
    border: 1px dotted gray;
    display: block;
    white-space: pre-wrap;
	font-family: monospace;
}

.osperOnboardingForm .preview {
	display: flex;
}

.osperOnboardingForm [type=reset], [type=submit], button, html [type=button] {
    -webkit-appearance: button;
    border: 1px solid white;
    border-radius: 0.5em;
    margin: 1em;
    background: var(--brand-main-color);
    color: var(--brand-reverse-color);
    font-weight: bold;
    font-family: 'Lota Grotesque Bold';
	cursor:pointer;
}
.osperOnboardingForm [type=reset]:hover, [type=submit]:hover, button:hover, html [type=button]:hover {
	box-shadow: 2px 2px 8px rgba(0,0,0,0.2);
}

