#root .MuiButton-root {
    width: 100%;
    cursor: pointer;
    font-family: Gilroy Bold, sans-serif;
    -webkit-transition: all .3s ease-in-out;
    transition: all .3s ease-in-out;
    background-color:     var(--brand-main-color)  ;
    color: #fff;
    letter-spacing: normal;
    line-height: 1.5;
    font-size: 1em;
    padding: 0.8rem 2rem;
    margin-top: 2rem;
    border: 2px solid     var(--brand-main-color)   ;
    border-radius: 4em;
}

#root .MuiButton-root:hover{
    background-color:     white ;
    color: var(--brand-main-color);
}

#root .MuiButton-root.navButton{
    padding: .4rem 1.2rem !important;
    margin-top: 0px !important;
    font-family: Lota Grotesque Bold,sans-serif !important;
    font-size: 1em;
}

#root .MuiButton-root.auxButton{
    background-color: #6c757d;
    border-color: #6c757d
}
#root .MuiButton-root.auxButton:hover{
    background-color: white;
    color: #6c757d
}

.MuiButton-label{
    font-family: Lota Grotesque Bold,sans-serif;
    text-transform: none;

}