
.app-header>*{
  display:block;
  width:100%;
  height: 5rem; /*for IE*/
  height:var(--header-height);
  transition: height ease 0.5s;
  overflow: hidden;
}

.app-header>.contents{
  display: flex;
  justify-content: space-between;
  /* background-color: #1830ad; 
  background-color: var(--brand-main-color); */
  color: white; /* for IE*/;
  color: var(--brand-reverse-color);
  /*box-shadow: 0 0 8px var(--brand-main-color);*/
  z-index: 10;
  padding: calc(5rem * 0.30) calc(5rem * 0.15);
  padding: calc(var(--header-height) * 0.30) calc(var(--header-height) * 0.15);
  box-sizing: border-box;
}
.app-header>.success{
  background-color: #66B114; /* for IE*/
  background-color: var(--success-background-color);
  color: white; /* for IE*/;
  color: var(--main-background-color);
  box-shadow: 0 0 8px var(--success-background-color);
  z-index: 1000;
}

.header_logo {
  color: var(--brand-main-color);
}

@media (min-width: 36em) {
  header.header .header_logo svg {
    width: 10.8rem;
    height: 3.36rem;
  }
}



.app-footer{
  /* to avoid horizontal scroll*/
  width: 99vw;
  height: 2rem; /*for IE*/
  height: var(--footer-height);
}
.app-footer>*{
  display: block;
  width: 100%;
  height: 2rem; /*for IE*/
  height: var(--footer-height);
  transition: height ease 0.5s;
  overflow: hidden;
}
.app-footer>.contents{
  position: absolute;
  bottom:0;
  left:0;
  background-color: #666666 /* for IE*/;
  background-color: var(--reverse-background-color);
  color: #bbbbbb; /*for IE*/
  color: var(--reverse-text-color);
  z-index: 1000;
  height: 2rem; /*for IE*/
  height: var(--footer-height);
  /* Left and right padding cuases horizontal scroll so chnge it to margin for inner contents*/
  padding: 0.5em 0;
}
.footer_content{
  margin:0 0.5em;
}
.logoutBtn {
    display: block;
    color: white;
    text-decoration: none;
    border: 1px solid white;
    border-radius: 1em;
    height: 2em;
    line-height: 2em;
    padding: 0 1em;
    cursor: pointer;
}


footer.footer {
  background-color: #1830ad;
  padding: 2.3rem 0 2.45rem
}

@media (min-width:48em) {
  footer.footer {
      padding: 3.5rem 0 2.5rem
  }
}

footer.footer .footer_logo {
  display: inline-block;
  margin-bottom: .6rem;
  width:5em;
  color: var(--brand-reverse-color);
}

footer.footer .footer_logo img {
  height: 100%;
  width: 4rem
}

@media (min-width:48em) {
  footer.footer .footer_logo img {
      width: 5.6rem
  }
}

footer.footer .footer_row a,
footer.footer .footer_row p {
  color: #fff;
  margin: 0
}

footer.footer .footer_row a {
  display: block;
  color: #fff;
  -webkit-transition: color .3s ease-in-out;
  transition: color .3s ease-in-out
}

footer.footer .footer_row a:hover {
  color: #e6e6e6
}

footer.footer .footer_address {
  margin-bottom: 1rem
}

@media (min-width:48em) {
  footer.footer .footer_address {
      margin-bottom: 1.25rem
  }
}

@media (min-width:62em) {
  footer.footer .footer_address {
      margin-bottom: 1.25rem
  }
}

footer.footer .footer_address p {
  font-size: .9rem;
  line-height: 1.71
}

footer.footer .footer_contact {
  margin-bottom: 1.25rem
}

@media (min-width:48em) {
  footer.footer .footer_contact:last-child {
      margin-bottom: 0
  }
}

footer.footer .footer_contact a {
  font-size: .9rem;
  line-height: 1.71
}

footer.footer .footer_contact p {
  display: -webkit-inline-box;
  display: -webkit-inline-flex;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column
}

footer.footer .footer_links>div {
  margin-bottom: 1rem
}

@media (min-width:48em) {
  footer.footer .footer_links>div {
      margin-bottom: 0
  }
}

footer.footer .footer_links a {
  font-family: Gilroy Bold, sans-serif;
  margin-bottom: 0.5rem
}

footer.footer .footer_links a:not(.footer_links_heading) {
  font-size: .7rem;
  line-height: 1.71
}

@media (min-width:48em) {
  footer.footer .footer_links a {
      margin-bottom: .9rem
  }
}

@media (min-width:62em) {
  footer.footer .footer_links a {
      margin-bottom: 1rem
  }
}

footer.footer .footer_links_heading {
  font-size: 1rem;
  line-height: 1.38;
  letter-spacing: -.01rem;
  text-transform: none
}

@media (min-width:24em) {
  footer.footer .footer_bottom {
      margin-top: 1.5rem
  }
}

@media (min-width:31em) {
  footer.footer .footer_bottom {
      margin-top: 2rem
  }
}

@media (min-width:37.5em) {
  footer.footer .footer_bottom {
      margin-top: 2.5rem
  }
}

footer.footer .footer_bottom_right {
  margin-top: .8rem
}

footer.footer .footer_bottom p {
  color: #fff;
  font-size: .7rem;
  line-height: 1.44;
  margin-bottom: 0
}

footer.footer .h4,
footer.footer h4 {
  color: #fff;
  font-size: calc(1.075rem + .9375vw);
  line-height: 1.08;
  letter-spacing: -.005rem;
  margin-bottom: 1.25rem;
  font-family: Gilroy Bold, sans-serif;
}

@media (min-width:90em) {
  footer.footer .h4,
  footer.footer h4 {
      font-size: 1.7rem
  }
}

@media (min-width:48em) {
  footer.footer .h4,
  footer.footer h4 {
      margin-bottom: 1.6rem
  }
}

@media (min-width:62em) {
  footer.footer .h4,
  footer.footer h4 {
      margin-bottom: 2.1rem
  }
}

@media (min-width:75em) {
  footer.footer .h4,
  footer.footer h4 {
      margin-bottom: 2.6rem
  }
}

footer.footer .h5,
footer.footer h5 {
  color: #fff;
  font-family: Lota Grotesque Light, sans-serif;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.38;
  letter-spacing: -.01rem;
  text-transform: none;
  margin-bottom: 1rem
}

@media (min-width:48em) {
  footer.footer .h5,
  footer.footer h5 {
      margin-bottom: 1rem
  }
}


.root {
  flex-grow: 1,
}
.menuButton {
  /* margin-right: theme.spacing(2); */
  color: var(--brand-main-color)
}
.closeIcon {
  position: absolute;
  top: 10px;
  right: 10px;
  color: var(--brand-main-color)
}
.title {
  flex-grow: 1;
  padding: 1.4rem 2.7rem;
  font-weight: 600;
  color: var(--brand-main-color);
  font-family: Lota Grotesque Light,sans-serif;
  font-size: 1em

}
.appBarTransparent {
  background-color: var(--main-background-color) !important;
  box-shadow: 0 -2px 10px 0 rgba(0,0,0,.5) !important;

}
.appBarSolid {
  background-color: var(--main-background-color) !important;
  box-shadow: none !important;
}
.navContainer {
  align-items: center
}
.drawerContainer{
  max-width: 121px
  
}
.drawerContainer h6{
    padding-left: 0px
  
}

footer.footer {
  background-color: #1830ad;
  padding: 5.5rem 0 4.9rem
}

@media (min-width:48em) {
  footer.footer {
      padding: 7rem 0 4.9rem
  }
}

footer.footer .footer_logo {
  display: inline-block;
  margin-bottom: 1.25rem
}

footer.footer .footer_logo img {
  height: 100%;
  width: 8rem
}

@media (min-width:48em) {
  footer.footer .footer_logo img {
      width: 11.3rem
  }
}

footer.footer .footer_row a,
footer.footer .footer_row p {
  color: #fff;
  margin: 0
}

footer.footer .footer_row a {
  display: block;
  color: #fff;
  -webkit-transition: color .3s ease-in-out;
  transition: color .3s ease-in-out
}

footer.footer .footer_row a:hover {
  color: #e6e6e6
}

footer.footer .footer_address {
  margin-bottom: 1.5rem
}

@media (min-width:48em) {
  footer.footer .footer_address {
      margin-bottom: 2.5rem
  }
}

@media (min-width:62em) {
  footer.footer .footer_address {
      margin-bottom: 2.5rem
  }
}

footer.footer .footer_address p {
  font-size: 1.4rem;
  line-height: 1.71
}

footer.footer .footer_contact {
  margin-bottom: 2.5rem
}

@media (min-width:48em) {
  footer.footer .footer_contact:last-child {
      margin-bottom: 0
  }
}

footer.footer .footer_contact a {
  font-size: 1.4rem;
  line-height: 1.71
}

footer.footer .footer_contact p {
  display: -webkit-inline-box;
  display: -webkit-inline-flex;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column
}

footer.footer .footer_links>div {
  margin-bottom: 1.5rem
}

@media (min-width:48em) {
  footer.footer .footer_links>div {
      margin-bottom: 0
  }
}

footer.footer .footer_links a {
  font-family: Gilroy Bold, sans-serif;
  margin-bottom: 1rem
}

footer.footer .footer_links a:not(.footer_links_heading) {
  font-size: 1.4rem;
  line-height: 1.71
}

@media (min-width:48em) {
  footer.footer .footer_links a {
      margin-bottom: 1.75rem
  }
}

@media (min-width:62em) {
  footer.footer .footer_links a {
      margin-bottom: 2rem
  }
}

footer.footer .footer_links_heading {
  font-size: 1.6rem;
  line-height: 1.38;
  letter-spacing: -.02rem;
  text-transform: none
}

@media (min-width:48em) {
  footer.footer .footer_bottom {
      margin-top: 3rem
  }
}

@media (min-width:62em) {
  footer.footer .footer_bottom {
      margin-top: 4rem
  }
}

@media (min-width:75em) {
  footer.footer .footer_bottom {
      margin-top: 5rem
  }
}

footer.footer .footer_bottom_right {
  margin-top: 1.6rem
}

footer.footer .footer_bottom p {
  color: #fff;
  font-size: 1.4rem;
  line-height: 1.44;
  margin-bottom: 0
}

footer.footer .h4,
footer.footer h4 {
  color: #fff;
  font-size: calc(2.15rem + .9375vw);
  line-height: 1.08;
  letter-spacing: -.01rem;
  margin-bottom: 2.5rem
}

@media (min-width:90em) {
  footer.footer .h4,
  footer.footer h4 {
      font-size: 3.6rem
  }
}

@media (min-width:48em) {
  footer.footer .h4,
  footer.footer h4 {
      margin-bottom: 3.2rem
  }
}

@media (min-width:62em) {
  footer.footer .h4,
  footer.footer h4 {
      margin-bottom: 4.2rem
  }
}

@media (min-width:75em) {
  footer.footer .h4,
  footer.footer h4 {
      margin-bottom: 5.2rem
  }
}

footer.footer .h5,
footer.footer h5 {
  color: #fff;
  font-family: Lota Grotesque Light, sans-serif;
  font-size: 1.6rem;
  font-weight: 400;
  line-height: 1.38;
  letter-spacing: -.02rem;
  text-transform: none;
  margin-bottom: 1rem
}

@media (min-width:48em) {
  footer.footer .h5,
  footer.footer h5 {
      margin-bottom: 1.5rem
  }
}